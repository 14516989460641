import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import ProductWithImage from "../../../components/ProductWithImage";

import logisticsCustBooking from "../../images/logistics-cust-booking.png";
import logisticsCustBooking2 from "../../images/logistics-cust-booking@2x.png";
import logisticsCustBooking3 from "../../images/logistics-cust-booking@3x.png";

import logisticsTrackingNotification from "../../images/logistics-tracking-notification.png";
import logisticsTrackingNotification2 from "../../images/logistics-tracking-notification@2x.png";
import logisticsTrackingNotification3 from "../../images/logistics-tracking-notification@3x.png";

import logisticsDriveStore from "../../images/logistics-drive-store.png";
import logisticsDriveStore2 from "../../images/logistics-drive-store@2x.png";
import logisticsDriveStore3 from "../../images/logistics-drive-store@3x.png";

import logisticsCollectGoods from "../../images/logistics-collect-goods.png";
import logisticsCollectGoods2 from "../../images/logistics-collect-goods@2x.png";
import logisticsCollectGoods3 from "../../images/logistics-collect-goods@3x.png";

import "./style.css";
import ListView from "../../../components/ListView";
import WithProviders from "../../../components/WithProviders";
import { Message } from "../../../lib/getMessage";
const bookingCustDesc = (isDirectionRTL) => (
  <ListView
    heading={<Message dictKey="products.collectandgo.booking.description" />}
    lists={[
      <Message dictKey="products.collectandgo.booking.item1" />,
      <Message dictKey="products.collectandgo.booking.item2" />,
      <Message dictKey="products.collectandgo.booking.item3" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);
const trackingNotificationDesc = (isDirectionRTL) => (
  <ListView
    heading={
      <Message dictKey="products.collectandgo.trackingandnotification.description" />
    }
    lists={[
      <Message dictKey="products.collectandgo.trackingandnotification.item1" />,
      <Message dictKey="products.collectandgo.trackingandnotification.item2" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);
const driveStoreDesc = (isDirectionRTL) => (
  <ListView
    heading={
      <Message dictKey="products.collectandgo.drivetostore.description" />
    }
    lists={[
      <Message dictKey="products.collectandgo.drivetostore.item1" />,
      <Message dictKey="products.collectandgo.drivetostore.item2" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);
const collectGoodsDesc = (isDirectionRTL) => (
  <ListView
    heading={
      <Message dictKey="products.collectandgo.collectgoods.description" />
    }
    lists={[
      <Message dictKey="products.collectandgo.collectgoods.item1" />,
      <Message dictKey="products.collectandgo.collectgoods.item2" />,
      <Message dictKey="products.collectandgo.collectgoods.item3" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const ClickAndCollect = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="pr-cac">
          <HeroBanner
            title={<Message dictKey="products.collectandgo.title" />}
            className={`pr-cac-banner ${isDirectionRTL && "pr-cac-banner-rtl"}`}
            isDirectionRTL={isDirectionRTL}
          />

          <section className="pr-cac-rprt">
            <ProductWithImage
              name="booking-cust"
              className="booking-cust"
              description={[
                {
                  type: "component",
                  val: bookingCustDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsCustBooking,
                xx: logisticsCustBooking2,
                xxx: logisticsCustBooking3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="tracking-notification"
              className="tracking-notification"
              description={[
                {
                  type: "component",
                  val: trackingNotificationDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsTrackingNotification,
                xx: logisticsTrackingNotification2,
                xxx: logisticsTrackingNotification3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="drive-to-store"
              className="drive-to-store"
              description={[
                {
                  type: "component",
                  val: driveStoreDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsDriveStore,
                xx: logisticsDriveStore2,
                xxx: logisticsDriveStore3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="collect-goods"
              className="collect-goods"
              description={[
                {
                  type: "component",
                  val: collectGoodsDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsCollectGoods,
                xx: logisticsCollectGoods2,
                xxx: logisticsCollectGoods3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(ClickAndCollect);
